@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('general-sans.css');
 

@layer base {
  :root {
    --background: bg-white-1;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: bg-white-1;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer base {
  p, li {
    @apply text-gray-500 text-base;
  }
  b {
    @apply font-semibold text-green-1 text-h5 text-base;
  }
}

@layer components {
  .primary-h1 {
    @apply text-h1 text-green-1 font-headline mb-4 leading-none text-pretty;
  }
  .primary-h2 {
    @apply text-h2 font-headline leading-none text-pretty text-green-1 mb-4 ;
  }
  .primary-h3 {
    @apply text-h4 md:text-h3 font-headline text-pretty text-green-2;
  }
  .primary-button1 {
    @apply text-green-1 bg-green-2 w-fit px-4 py-2 rounded-lg hover:bg-green-2/90 font-semibold border-gray-2 h-auto;
  }
  .primary-button2 {
    @apply text-white-1 bg-green-1 w-fit px-4 py-2 rounded-xl border-gray-2;
  }
  .secondary-button1 {
    @apply  bg-white border-gray-300 border w-fit px-4 py-2 rounded-xl border-gray-2;
  }
  .primary-link {
    @apply text-green-1 hover:text-green-1 underline underline-offset-2 hover:cursor-pointer hover:underline;
  }
}

@layer utilities {
  .font-headline-light {
    font-weight: 300;
  }
}

.markdown-content h3 {
  font-size: 1.25rem; /* equivalent to text-xl in Tailwind */
  font-weight: 600; /* equivalent to font-semibold in Tailwind */
  margin-bottom: 1rem; /* equivalent to mb-4 in Tailwind */
}

.markdown-content p,
.markdown-content li {
    margin-bottom: 1rem; /* equivalent to mb-4 in Tailwind */
    line-height: 1.5; /* for better readability */
}

.markdown-content ul {
  padding-left: 1.5rem; /* Add some padding to the left of the list */
}

.markdown-content ul li {
  list-style-type: disc; /* Standard disc-style bullet points */
  /* For custom bullet style, use 'list-style-type: none;' and add a custom bullet using '::before' pseudo-element */
}

/* @font-face {
  font-family: 'IvyPresto Headline';
  src: url('./fonts/IvyPresto Headline Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IvyPresto Headline';
  src: url('./fonts/IvyPresto Headline Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'IvyPresto Headline';
  src: url('./fonts/IvyPresto Headline Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'IvyPresto Headline';
  src: url('./fonts/IvyPresto Headline Bold Italic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
} */

@font-face {
  font-family: 'IvyPresto Headline';
  src: url('./fonts/IvyPresto Headline Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
/* 
@font-face {
  font-family: 'IvyPresto Headline';
  src: url('./fonts/IvyPresto Headline Light Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'IvyPresto Headline';
  src: url('./fonts/IvyPresto Headline SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'IvyPresto Headline';
  src: url('./fonts/IvyPresto Headline SemiBold Italic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
} */

@font-face {
  font-family: 'IvyPresto Headline';
  src: url('./fonts/IvyPresto Headline Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

/* @font-face {
  font-family: 'IvyPresto Headline';
  src: url('./fonts/IvyPresto Headline Thin Italic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
} */